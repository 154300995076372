<template>
  <div>
    <div class="d-flex align-center mb-8">
      <div>
        <v-img
          src="@/assets/images/logos/pasid.png"
          width="50"
        ></v-img>
      </div>
      <div class="ms-4">
        <h3 class="font-weight-bold text-subtitle-1 text-md-h5 black--text">
          Sistem Informasi dan Komunikasi Pendidikan <br>Berbasis Teknologi Kota Padang Sidempuan
        </h3>
      </div>
    </div>
    <v-row>
      <v-col
        v-for="card,index in cards"
        :key="index"
        cols="12"
        sm="6"
        md="3"
        lg="3"
      >
        <v-card
          :loading="isLoadingCard"
        >
          <div class="d-flex justify-md-space-around align-center pa-md-4 pa-6">
            <div>
              <v-avatar :color="card.color">
                <v-container class="d-flex align-center">
                  <v-img :src="card.icon"></v-img>
                </v-container>
              </v-avatar>
            </div>
            <div class="ps-md-0 ps-4">
              <p class="text-subtitle-1 black--text">
                {{ card.title }}
              </p>
              <p class="font-weight-bold text-h5 font-weight-bold black--text">
                {{ card.total }}
              </p>
              <router-link
                class="text-decoration-none"
                :to="card.url"
              >
                Lihat Detail
              </router-link>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import iconMonitor from '@/assets/icons/white/monitor.svg'
import iconReceipt from '@/assets/icons/white/receipt-2.svg'
import iconUser from '@/assets/icons/white/user.svg'

export default {
  name: 'DashboardHeaddepartment',
  data() {
    return {
      items: [
        {
          total: 12,
          title: 'Jumlah Sekolah',
          icon: '',
        },
        {
          total: 8,
          title: 'Jumlah Pengumuman',
          icon: '',
        },
        {
          total: 16,
          title: 'Jumlah Berita',
          icon: '',
        },
        {
          total: 16,
          title: 'Jumlah Slider',
          icon: '',
        },
      ],
      histories: [
        {
          title: 'E-Learning',
          total: 70,
          subject: 'Bahasa Indonesia',
          persentase: '70%',
          last_seen: '10 Menit yang lalu',
          color: 'primary',
        },
        {
          title: 'Bank Soal',
          total: 50,
          subject: 'Matematika',
          last_seen: '1 Jam yang lalu',
          color: 'success',
        },
      ],
      kadis_uuid: '',
      dashboards: [],
      count_slider: 0,
      count_news: 0,
      count_announcement: 0,
      count_school: 0,
      isLoadingCard: true,
      cards: [
        {
          title: 'Total Berita',
          keyName: 'news',
          total: 0,
          icon: iconUser,
          url: 'news-headdepartment',
          color: '#00913E',
        },
        {
          title: 'Total Sekolah',
          keyName: 'school',
          total: 0,
          icon: iconUser,
          url: 'school-headdepartment',
          color: '#F7B239',
        },
        {
          title: 'Total Pengumuman',
          keyName: 'announcement',
          total: 0,
          icon: iconReceipt,
          url: 'announcement-headdepartment',
          color: '#7D2B8B',
        },
        {
          title: 'Total Slider',
          keyName: 'slider',
          total: 0,
          icon: iconMonitor,
          url: 'slider-headdepartment',
          color: '#2197D8',
        },
      ],
    }
  },
  beforeMount() {
    this.getHeaddepartmentUuid()
  },
  mounted() {
    this.getDataCount()
  },
  methods: {
    getHeaddepartmentUuid() {
      this.kadis = JSON.parse(localStorage.getItem('user')).user.details
      this.kadis.forEach(el => {
        this.kadis_uuid = el.uuid
        console.log(this.kadis_uuid)
      })

      return true
    },
    async getDataCount(params = {}) {
      await this.$services.ApiServices.list('dashboardheaddepartment', { ...params, kadis_uuid: this.kadis_uuid }).then(
        ({ data }) => {
          Object.keys(data.data).forEach((key, index) => {
            this.cards.forEach(el => {
              if (el.keyName === key) {
                el.total = data.data[key]
              }
            })
          })
        },
      )
      this.isLoadingCard = false
    },
  },
}
</script>

<style>
.card-info,
.card-img {
  z-index: 1;
}
.card-info .font-weight,
.card-info .font-light {
  color: #fff;
}
</style>
